import { Link } from 'gatsby';
import * as React from 'react';
import styled from 'styled-components';
import "../index.scss"
import { Container, FlexProps } from '../styles/grids';

const menuItems: string[] = ['events', 'theater', 'music'];

const HeaderRow = styled.div<FlexProps>`
  display: flex;
  flex: ${(props) => (props.flex ? props.flex : 'initial')};
  flex-direction: row-reverse;
  flex-wrap: "initial";
  align-content: "initial";
  justify-content: "initial";
  @media screen and (max-width: 768px) {
    flex-direction: "row";
  }
`;

const StyledMenuItem = styled(Link)`
    text-decoration: none;
    color: #F3867F;

  h2 {
    &:hover{
        color: #EFE5EF;
        cursor: pointer;
    }
  }
  
  @media only screen and (max-width: 767px) {
    font-size: 12px;
    h2 {
        font-size: 14px;
    }
  }

  @media only screen and (max-width: 320px) {
    font-size: 8px;
    h2 {
        font-size: 14px;
    }
  }
`;


const Header: React.FC = () => {

    return (
        <header>
            <Container>
                <HeaderRow>
                   {menuItems.map((menuItem, key) => {
                        return(
                            <StyledMenuItem to={`/${menuItem}`} key={key}>
                                <h2>{' ~ '}{menuItem}{' ~ '}</h2>
                            </StyledMenuItem>
                        );
                    })}
                </HeaderRow>
            </Container>
        </header>
    );
};

export default Header;
