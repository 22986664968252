import { Link } from 'gatsby';
import * as React from 'react';
import styled from 'styled-components';
import "../index.scss"
import { Container, Row } from '../styles/grids';

const StyledMenuItem = styled(Link)`
    text-decoration: none;
    color: #F3867F;

  h2 {
    &:hover{
        color: #EFE5EF;
        cursor: pointer;
    }
  }
  @media only screen and (max-width: 767px) {
    font-size: 12px;
    h2 {
        font-size: 14px;
    }
  }

  @media only screen and (max-width: 320px) {
    font-size: 8px;
    h2 {
        font-size: 14px;
    }
  }
`;

const menuItems: string[] = ['bio', 'teaching', 'contact'];

const Footer: React.FC = () => {

    return (
        <footer>
            <Container>
                <Row>
                   {menuItems.map((menuItem, key) => {
                        return(
                            <StyledMenuItem to={`/${menuItem}`} key={key}>
                                <h2>{' ~ '}{menuItem}{' ~ '}</h2>
                            </StyledMenuItem>
                        );
                    })}
                </Row>
            </Container>
        </footer>
    );
};

export default Footer;
