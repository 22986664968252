import { graphql, useStaticQuery } from 'gatsby';
import React, { ReactNode } from 'react';
import { Helmet } from 'react-helmet';
import Footer from './components/footer';
import Header from './components/header';
import "./index.scss"
import { MainWrapper } from './styles/grids';

interface LayoutProps {
    children: ReactNode;
}

interface LayoutData {
    site: {
        siteMetadata: {
            name: string,
            description: string,
        },
    };
}

const Layout: React.FC<LayoutProps> = ({ children }: LayoutProps) => {

    const { site }: LayoutData = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    name
                    description
                }
            }
        }
    `);

    const {
        name,
        description,
    } = site.siteMetadata;

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Marija Kovačević</title>
            </Helmet>
            <MainWrapper>
                <Header />
                    <main>{children}</main>
                <Footer />
            </MainWrapper>
        </>
    );
};

export default Layout;
